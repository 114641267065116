<template>
  <!-- 短信业务代码 -->
  <div class="SMS-service-code-page">
    <div class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="info">
      <div class="add-btn">
        <el-button
          v-if="listFind('添加')"
          type="primary"
          size="small"
          @click="openDialogFun('新增')"
          >添加</el-button
        >
      </div>
      <div class="table-box">
        <Table
          :table-data="tableData"
          :title-name="titleName"
          col-class-type
        ></Table>
      </div>
      <div ref="pagination" class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="业务代码：" prop="smsBusinessCode">
            <el-input
              v-model.trim="ruleForm.smsBusinessCode"
              placeholder="请输入业务代码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="业务名称：" prop="smsBusinessName">
            <el-input
              v-model.trim="ruleForm.smsBusinessName"
              placeholder="请输入业务名称"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            v-if="dialogTitle === '新增'"
            @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirmFun">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  querySmsBusinessCodePage,
  saveSmsBusinessCode,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      ruleForm: {
        smsBusinessCode: "",
        smsBusinessName: "",
      },
      form: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      rules: {
        smsBusinessCode: [
          { required: true, message: "请输入业务代码", trigger: "blur" },
        ],
        smsBusinessName: [
          { required: true, message: "请输入业务名称", trigger: "blur" },
        ],
      },
      tableData: [],
      titleName: [
        {
          title: "业务代码",
          props: "smsBusinessCode",
        },
        {
          title: "业务名称",
          props: "smsBusinessName",
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
      }
    },
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    // 返回列表
    returnList() {
      this.$router.push("/projectTravel/SMSTemplateConfiguration");
    },
    /**
     * @description 请求列表数据
     */
    getTableData() {
      querySmsBusinessCodePage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
          this.total = res.data.total;
        }
      });
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.getTableData();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.getTableData();
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      this.dialogVisible = true;
    },
    /**
     * @description 确认回调
     */
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          saveSmsBusinessCode(this.ruleForm).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("新增成功");
              this.dialogVisible = false;
              this.getTableData();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.SMS-service-code-page {
  height: calc(100% - 52px);
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  i {
    cursor: pointer;
  }
  .table-box {
    margin-top: 20px;
  }
  .info {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background-color: #fff;
    margin-top: 10px;
    box-sizing: border-box;
    // overflow: auto;
    // box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
